import React, {useState} from 'react';
import NavBar from './navbar';
import CSVReader from './CSVDropZone';
import Table, {CyrptoIcon} from './Table';  // new

const FILL_HEADERS = ['portfolio', 'trade id', 'product', 'side', 'created at', 'size', 'size unit', 'price', 'fee', 'total', 'price/fee/total unit'];
const ACCOUNT_HEADERS = ['portfolio', 'type', 'time', 'amount', 'balance', 'amount/balance unit', 'transfer id', 'trade id', 'order id'];

const ACCOUNT_INPUT_HEADERS = [
  {
    Header: 'Portfolio',
    accessor: 'portfolio',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Time',
    accessor: 'time',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Amount/balance unit',
    accessor: 'amount/balance unit',
    Cell: CyrptoIcon
  },
  {
    Header: 'Transfer ID',
    accessor: 'transfer id'
  },
  {
    Header: 'Trade ID',
    accessor: 'trade id'
  },
  {
    Header: 'Order ID',
    accessor: 'order id'
  }
];

const ACCOUNT_OUTPUT_HEADERS = [
  {
    Header: 'Portfolio',
    accessor: 'portfolio',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Time',
    accessor: 'time',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Priced at the deposit time',
    accessor: 'priced at',
  },
  {
    Header: 'Total in USD',
    accessor: 'total in USD',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Amount/balance unit',
    accessor: 'amount/balance unit',
    Cell: CyrptoIcon
  },
  {
    Header: 'Transfer ID',
    accessor: 'transfer id'
  },
  {
    Header: 'Trade ID',
    accessor: 'trade id'
  },
  {
    Header: 'Order ID',
    accessor: 'order id'
  }
];

const FILL_INPUT_HEADERS = [
  {
    Header: 'Portfolio',
    accessor: 'portfolio',
  },
  {
    Header: 'Trade id',
    accessor: 'trade id',
  },
  {
    Header: 'Product',
    accessor: 'product',
  },
  {
    Header: 'Side',
    accessor: 'side',
  },
  {
    Header: 'Created at',
    accessor: 'created at',
  },
  {
    Header: 'Size',
    accessor: 'size',
  },
  {
    Header: 'Size unit',
    accessor: 'size unit',
    Cell: CyrptoIcon
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Fee',
    accessor: 'fee',
  },
  {
    Header: 'Total',
    accessor: 'total',
  },
  {
    Header: 'Price/Fee/Total unit',
    accessor: 'price/fee/total unit',
  },
];

const FILL_OUTPUT_HEADERS = [
  {
    Header: 'Portfolio',
    accessor: 'portfolio',
  },
  {
    Header: 'Product',
    accessor: 'product',
  },
  {
    Header: 'Side',
    accessor: 'side',
  },
  {
    Header: 'Created at',
    accessor: 'created at',
  },
  {
    Header: 'Size',
    accessor: 'size',
  },
  {
    Header: 'Size unit',
    accessor: 'size unit',
    Cell: CyrptoIcon
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Average price',
    accessor: 'average_price',
  },
  {
    Header: 'Fee',
    accessor: 'fee',
  },
  {
    Header: 'Total',
    accessor: 'total',
  },
  {
    Header: 'Price/Fee/Total unit',
    accessor: 'price/fee/total unit',
  },
  {
    Header: 'Count',
    accessor: 'count',
  },
];

const FillParser = (combinedDataObject, data) => {
  const action = data['side'].toLowerCase();
  const diliminator = `${data['created at'].split('T')[0]}-${data['product']}-${data['side']}`;
  if (combinedDataObject[diliminator]) {
    if (action === 'sell') {
      combinedDataObject[diliminator].size -= parseFloat(data['size']);
    } else {
      combinedDataObject[diliminator].size += parseFloat(data['size']);
    }
    combinedDataObject[diliminator].fee += parseFloat(data['fee']);
    combinedDataObject[diliminator].total += parseFloat(data['total']);
    combinedDataObject[diliminator].count += 1;
    combinedDataObject[diliminator].temp_price += parseFloat(data['price']);
    combinedDataObject[diliminator].average_price = (
      combinedDataObject[diliminator].temp_price / combinedDataObject[diliminator].count
    );
    combinedDataObject[diliminator].fills.push(data);
  } else {
    combinedDataObject[diliminator] = {
      ...data,
      'size': action === 'sell' ? -parseFloat(data['size']) :  parseFloat(data['size']),
      'price': parseFloat(data['price']),
      'fee': parseFloat(data['fee']),
      'total': parseFloat(data['total']),
      'count': 1,
      'average_price': parseFloat(data['price']),
      'fills': [data],
      'temp_price': parseFloat(data['price']),
    };
  }
};

const AccountParser = (data) => {
  if (data['type'] !== 'deposit' || !data['transfer id']) {
    return Promise.resolve(
      {
        ...data,
        'priced at': '',
        'total in USD': '',
      }
    );
  }
  const unit = data['amount/balance unit'].toUpperCase();
  if (data['amount/balance unit'].toUpperCase() === 'USD') {
    return Promise.resolve(
      {
        ...data,
        'priced at': '',
        'total in USD': '',
      }
    );
  }
  console.log('converting to USD: ', unit);
  return fetch(`https://api.coinbase.com/v2/prices/${unit}-USD/spot?date=${data['time']}`)
    .then(res => res.json())
    .then(e => {
      return {
        ...data,
        'priced at': e.data.amount,
        'total in USD': parseFloat(e.data.amount) * parseFloat(data['amount'])
      };
    });
};


const App = () => {
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [inputHeaders, setInputHeaders] = useState([]);
  const [outputHeaders, setOutputHeaders] = useState([]);

  const parseCSV = ({data, errors}) => {
    setErrors(errors);
    const [headers, ...rows] = data;
    const sortedHeaders = JSON.stringify([...headers].sort());
    const fillHeaderErrors = sortedHeaders !== JSON.stringify(FILL_HEADERS.sort());
    const accountHeaderErrors = sortedHeaders !== JSON.stringify(ACCOUNT_HEADERS.sort());
    if (fillHeaderErrors && accountHeaderErrors) {
      setErrors(['CSV file does not have the correct headers.']);
      return;
    }
    const headersToUse = fillHeaderErrors ? ACCOUNT_HEADERS : FILL_HEADERS;
    const newData = [];
    const combinedDataObject = {};

    rows.forEach((row) => {
      if (row.length === headersToUse.length) {
        const newRow = {};
        headers.forEach((header, index) => {
          if (row[index] !== undefined) {
            newRow[header] = row[index];
          }
        });
        newData.push(newRow);
      }
    });
    if (fillHeaderErrors) {
      setInputHeaders(ACCOUNT_INPUT_HEADERS);
      setOutputHeaders(ACCOUNT_OUTPUT_HEADERS);
      Promise.all(newData.map(AccountParser))
        .then((results) => {
          setData(newData);
          setCombinedData(results);
        });
    } else {
      setInputHeaders(FILL_INPUT_HEADERS);
      setOutputHeaders(FILL_OUTPUT_HEADERS);
      setData(newData);
      newData.forEach(data => FillParser(combinedDataObject, data));
      setCombinedData(Object.values(combinedDataObject));
    }
  };

  return (
    <>
      <NavBar/>
      <div className='max-w-full mx-auto rounded-xl md:max-w-full w-4/5 pt-10'>
        <CSVReader parseCSV={parseCSV} />
        {errors.length > 0 && (
          <div className='pt-10 pb-5'>
            <div className="border text-halfDarkRed px-4 py-3 rounded">
              <strong className="font-bold">Errors:</strong>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {data.length > 0 && (
          <Table columns={inputHeaders} data={data} header="Input CSV" />
        )}
        {combinedData.length > 0 && (
          <Table columns={outputHeaders} data={combinedData} header="Output CSV" />
        )}
      </div>
    </>
  );
};

export default App;
