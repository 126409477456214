import React from 'react';
import {CSVLink} from 'react-csv';
import {useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination} from 'react-table';
import {ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon} from '@heroicons/react/solid';
import {Button, PageButton} from './shared/Button';
import {classNames} from './shared/Utils';
import {SortIcon, SortUpIcon, SortDownIcon} from './shared/Icons';


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-halfDarkGreen">Search: </span>
      <input
        type="text"
        className="rounded-md p-1"
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
      />
    </label>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: {filterValue, setFilter, preFilteredRows, id, render},
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render('Header')}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id} id={id} value={filterValue}
        onChange={e => {setFilter(e.target.value || undefined);}}>
        <option value="">All</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
}

export function StatusPill({value}) {
  const status = value ? value.toLowerCase() : 'unknown';

  return (
    <span
      className={
        classNames(
          'px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm',
          status.startsWith('active') ? 'bg-halfDarkGreen text-halfDarkBlack' : null,
          status.startsWith('inactive') ? 'bg-halfDarkYellow text-halfDarkBlack' : null,
          status.startsWith('offline') ? 'bg-halfDarkRed text-halfDarkBlack' : null,
        )
      }
    >
      {status}
    </span>
  );
}

export function CyrptoIcon({value}) {
  const name = value ? value.toLowerCase() : '_';

  if (!value) {
    return null;
  }

  return (
    <>
      <div className="flex items-center">
        <div className="flex-shrink-0 h-10 w-10">
          <img className="h-10 w-10 rounded-full" src={`/crypto-icons/${name}.svg`} alt="" />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-halfDarkYellow">{value}</div>
        </div>
      </div>
    </>
  );
}

export function AvatarCell({value, column, row}) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="h-10 w-10 rounded-full" src={row.original[column.imgAccessor]} alt="" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium text-halfDarkYellow">{value}</div>
        <div className="text-sm text-halfDarkYellow">{row.original[column.emailAccessor]}</div>
      </div>
    </div>
  );
}

function Table({columns, data, header}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data,
  },
  useFilters, // useFilters!
  useGlobalFilter,
  useSortBy,
  usePagination,  // new
  );

  const csvHeaders = React.useMemo(() => {
    return columns.map(column => {
      return {
        label: column.Header,
        key: column.accessor,
      };
    });
  }, [columns]);

  // Render the UI for your table
  return (
    <div className="pt-10">
      <h1 className="text-4xl pb-3 uppercase font-bold font-sans text-halfDarkYellow">{header}</h1>
      <div className="sm:flex sm:gap-x-2">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <CSVLink
          data={data}
          headers={csvHeaders}
          filename={`${header}.csv`}>
          <button className="bg-halfDarkYellow hover:bg-halfDarkRed text-halfDarkBlack py-1 px-4 rounded">
            Export CSV
          </button>
        </CSVLink>
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div>
      {/* table */}
      <div className="mt-4 flex flex-col">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border border-halfDarkYellow sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-halfDarkYellow">
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup, index) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column, index) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th key={index}
                          scope="col"
                          className="group px-6 py-3 text-left text-xs font-medium text-halfDarkPurple uppercase tracking-wider"
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className="flex items-center justify-between">
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? <SortDownIcon className="w-4 h-4 text-halfDarkYellow" />
                                  : <SortUpIcon className="w-4 h-4 text-halfDarkYellow" />
                                : (
                                  <SortIcon className="w-4 h-4 text-halfDarkYellow opacity-0 group-hover:opacity-100" />
                                )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()} className="bg-white divide-y divide-halfDarkYellow" >
                  {page.map((row, index) => {  // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, index) => {
                          return (
                            <td {...cell.getCellProps()}
                              key={index} className="px-6 py-4 whitespace-nowrap" role="cell" >
                              <div className="text-sm text-halfDarkYellow">{cell.render('Cell')}</div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Pagination */}
      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="flex gap-x-2 items-baseline">
            <span className="text-sm text-halfDarkYellow">
              Page <span className="font-medium">{state.pageIndex + 1}</span> of <span className="font-medium">{pageOptions.length}</span>
            </span>
            <label>
              <span className="sr-only">Items Per Page</span>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                value={state.pageSize}
                onChange={e => {setPageSize(Number(e.target.value));}} >
                {[5, 10, 20, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
              <PageButton className="rounded-l-md"  onClick={() => gotoPage(0)} disabled={!canPreviousPage} >
                <span className="sr-only">First</span>
                <ChevronDoubleLeftIcon className="h-5 w-5 text-halfDarkYellow" aria-hidden="true" />
              </PageButton>
              <PageButton onClick={() => previousPage()} disabled={!canPreviousPage} >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5 text-halfDarkYellow" aria-hidden="true" />
              </PageButton>
              <PageButton onClick={() => nextPage()} disabled={!canNextPage}>
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5 text-halfDarkYellow" aria-hidden="true" />
              </PageButton>
              <PageButton className="rounded-r-md" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} >
                <span className="sr-only">Last</span>
                <ChevronDoubleRightIcon className="h-5 w-5 text-halfDarkYellow" aria-hidden="true" />
              </PageButton>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Table;
