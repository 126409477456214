import React from 'react';
import Logo from './img/logo.svg';

const NavBar = () => {
  return (
    <nav className="relative flex flex-wrap items-center justify-between bg-halfDarkYellow py-1 shadow-lg z-50">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <span className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-black cursor-pointer" >
            <img src={Logo} alt="loc logo" className="h-10" />
          </span>
        </div>
      </div>
    </nav>
  );
};
export default NavBar;
