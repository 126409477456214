import React from 'react';

import {
  useCSVReader,
  formatFileSize,
} from 'react-papaparse';

const styles = {
  zone: {
    alignItems: 'center',
    color: '#E06C75',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  extraPadding: {
    border: '2px dashed #E5C07B',
    paddingTop: 20,
    paddingBottom: 20,
  },
  file: {
    border: '2px solid #E5C07B',
    borderRadius: 20,
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',
    zIndex: 10,
    paddingTop: 5,
    paddingBottom: 5,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

const CSVReader = (props) => {
  const {CSVReader} = useCSVReader();

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        console.log('---------------------------');
        console.log(results);
        console.log('---------------------------');
        props.parseCSV(results);
      }}
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDragLeave={(event) => {
        event.preventDefault();
      }}>
      {({
        getRootProps,
        acceptedFile,
        getRemoveFileProps,
        Remove,
      }) => (
        <div>
          <h1 className="text-4xl pb-3 uppercase font-bold font-sans text-halfDarkYellow">Tax calculator for Coinbase Pro</h1>
          <div
            {...getRootProps()}
            style={Object.assign(
              {},
              styles.zone,
              !acceptedFile && styles.extraPadding
            )}
            className="max-w-full mx-auto rounded-xl md:max-w-full"
          >
            {acceptedFile ? (
              <>
                <div style={styles.file}>
                  <div style={styles.info}>
                    <span style={styles.size}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span style={styles.name}>{acceptedFile.name}</span>
                  </div>
                  <div {...getRemoveFileProps()} style={styles.remove}>
                    <Remove color={'#E06C75'} />
                  </div>
                </div>
              </>
            ) : (
              'Click to upload'
            )}
          </div>
        </div>
      )}
    </CSVReader>
  );
};

export default CSVReader;
